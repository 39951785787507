<template>
  <div class="container">
    <div class="progress">
      <div class="progress__bar"></div>
    </div>
    <header>
      <div style="flex: 0 0 3.2rem"></div>
      <div class="logo" style="flex: 1 0 auto">
        <img src="~@/assets/logo.svg" alt="Oracles Report">
      </div>
      <div style="flex: 0 0 3.2rem">
        <div class="live">
          LIVE
        </div>
      </div>
    </header>
    <div class="content">
      <div class="grid">
        <div v-for="item in [1,2,3,4,5,6]" :key="item" class="oracle" :class="'oracle--' + item">
          <div class="oracle__image">
            <img src="~@/assets/oracle.png" alt="Oracle" draggable="false">
          </div>
          <div class="oracle__callouts">
            <label>Most used callouts<span>&nbsp;in last minute</span></label>
            <div class="callout" v-for="rank in [1,2,3]" :key="rank" :class="'callout--rank' + rank">
              <div class="callout__rank" :data-rank="rank"></div>
              <div class="callout__name">
                {{ callouts[rank - 1].titles[item - 1] }}
              </div>
              <div class="callout__change" :class="[callouts[rank - 1].change >= 0 ? 'callout__change--positive' : 'callout__change--negative']">
                <svg v-if="callouts[rank - 1].change >= 0" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#00E676"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"/></svg>
                <svg v-if="callouts[rank - 1].change < 0" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#FF1744"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 18l2.29-2.29-4.88-4.88-4 4L2 7.41 3.41 6l6 6 4-4 6.3 6.29L22 12v6z"/></svg>
                <small>&nbsp;{{ callouts[rank - 1].change }}%</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <span>💖 Made by the lovely folks at <a href="https://twitter.com/TrialsReport" target="_blank">@TrialsReport</a></span>
    </footer>
  </div>
</template>

<script>

const possibilities = [
  ['6','1','2','5','4','3'],
  ['Far Left', 'Far Mid', 'Far Right', 'Close Left', 'Close Mid', 'Close Right'],
  ['Second one is first', 'Third one is third', 'First one is first', 'Sixth one is fourth', 'First one is second', 'Second one is third'],
  ['Left', 'Spawn', 'Right', 'Mars', 'Mid', 'Venus'],
  ['That one', 'Eeeh', 'The other one', 'The last one', 'The red one', 'The loud one'],
  ['Left Big Close Far', 'Kinda Far Right Big Air', 'Right Super Close Portal', 'Far Left Side Stairs', 'Close Back Portal Left', 'Portal Second Portal Mid'],
  ['A', 'C', 'D', 'G', 'F#', 'E'],
  ['Left', 'Left', 'Left', 'Left', 'Left', 'Middle'],
  ['Atheon\'s Left Arm', 'Atheon\'s Head', 'Atheon\'s Right Arm', 'Atheon\'s Left Leg', 'Atheon\'s Torso', 'Atheon\'s Right Leg'],
  ['4', '5', '6', '3', '2', '1'],
  ['Duck', 'Goose', 'Horse', 'Mouse', 'Cat', 'Dog'],
  ['Chilling Bird', 'Boring Bird', '69 Fish', 'Infinity Snake', 'That dragon that looks to the right but doesn\'t have a spear', 'Smiley Snake'],
  ['Far', 'Over there', 'Kinda left', 'By me', 'There', 'No wait that other one'],
  ['Bedtime', '12 o\'clock', 'Quarter past two','Seven thirty', '6 o\'clock', 'Ten before 4'],
  ['B', 'U', 'N', 'E', 'I', 'G'],
  ['NERF', 'S', 'T', 'IS', 'S', 'A'],
  ['JUST', 'A', 'D', 'T', 'P', 'A'],
  ['A New Hope', 'The Empire Strikes Back', 'Return of the Jedi', 'The Phantom Menace', 'Attack of the Clones', 'Revenge of the Sith'],
  ['North-West', 'North', 'North-East', 'South-West', 'South', 'South-East'],
  ['Aaaah Supplicants', 'No', 'No', 'No', 'No', 'No'],
  ['Do', 'Re', 'Mi', 'La Ti Do', 'Sol', 'Fa'],
  ['What', 'Is', 'Up', 'Guys', 'Rick Kackis', 'Here'],
  ['Penne', 'Spaghetti', 'Fetuccini', 'Lasagne', 'Tortellini', 'Ravioli'],
  ['Farquad on a quad', 'Quad Farquad', 'Far Farquad on a far quad', 'Farquad on a quad jumping over Farquad on a quad', 'Quad Farquad on quad quads', 'Quad Farwuad on quads jumping over Farquad on quads'],
  ['L2', 'M2', 'R2', 'L1', 'M1', 'R1'],
  ['R2D2', 'R2M2', 'R2R2', 'R2L1', 'R2M1', 'R2R1'],
  ['Just', 'Make', 'A', 'And Hope', 'Cell', 'Warmind'],
  ['Cups', 'Dogs', 'Axes', 'Sun', 'Psion', 'Gauntlet'],
  ['Antumbra', 'Penumbra', 'Munumbra', 'Lopumbra', 'Futumbra', 'Cucumbra'],
  ['C', 'A', 'P', 'A', 'P', 'C'],
  ['Bring', 'Back', 'No', 'Land', 'Beyond', 'You Cowards'],
  ['High Left', 'High Mid', 'High Right', 'Past', 'Present', 'Future'],
  ['Harry Potter 1', 'Harry Potter 2', 'Harry Potter 3', 'Harry Potter 4', 'Harry Potter 5', 'Harry Potter 6'],
  ['Do', 'Not', 'Dead', 'Side', 'In', 'Open'],
];

export default {
  name: 'App',
  data () {
    return {
      callouts: []
    }
  },
  created () {
    document.documentElement.style.setProperty(`--screenHeight`, window.innerHeight + 'px');

    const shuffled = possibilities.sort(() => Math.random() - 0.5);

    this.callouts = [
      shuffled[0],
      shuffled[1],
      shuffled[2],
    ].map(p => ({
      titles: p,
      change: Math.round((Math.random() - .1) * 50)
    }));

    setTimeout(() => location.reload(), 60000);
  }
}
</script>

<style lang="scss">
@import 'normalize-scss';
@include normalize();

html {
  height: 100%;
  background: black;
}

* {
  box-sizing: border-box;
}

body {
  font: 400 16px / 1.6 'Roboto', sans-serif;
  overflow-x: hidden;
  color: white;
  position: relative;
  height: 100%;

  > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    position: fixed;
    left: 0;
    right: 0;
    height: var(--screenHeight);
    top: 0;
    content: "";
    background: url('/img/arena.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &:after {
    left: 0;
    right: 0;
    top: 0;
    height: var(--screenHeight);
    background: rgba(0,0,0,0.4);
    content: "";
    pointer-events: none;
    position: fixed;
  }
}

#app {
  height: 100%;
}

@keyframes progress {
  from { transform: translate3d(0, 0, 0); }
  to { transform: translate3d(-100%, 0, 0); }
}

@keyframes blink {
  0% { opacity: 0 }
  49% { opacity: 0 }
  50% { opacity: 1 }
  100% { opacity: 1 }
}


.progress {
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.5);
  flex: 0 0 auto;
  overflow: hidden;
}

.live {
  font-weight: 700;
  text-transform: uppercase;
  font-size: .9em;
  line-height: 1;
  color: #FF1744;
  animation: blink 2s infinite;

  &:before {
    width: 12px;
    height: 12px;
    content: "";
    background: #FF1744;
    border-radius: 50%;
    display: inline-block;
    margin-right: .25rem;
  }
}

@keyframes progress {
  from { transform: translate3d(0, 0, 0); }
  to { transform: translate3d(-100%, 0, 0); }
}

.progress__bar {
  height: 4px;
  width: 100%;
  background: #FF1744;
  animation: progress linear 60s;
  animation-fill-mode: forwards;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

header {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  padding: 1.5rem 1rem;
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: -8rem;
    pointer-events: none;
    content: "";
    opacity: .6;
    background-image: linear-gradient(rgb(0,0,0) 0px, rgba(0,0,0, 0.74) 19%, rgba(0,0,0, 0.54) 34%, rgba(0,0,0, 0.38) 47%, rgba(0,0,0, 0.28) 56.5%, rgba(0,0,0, 0.19) 65%, rgba(0,0,0, 0.13) 73%, rgba(0,0,0, 0.08) 80.2%, rgba(0,0,0, 0.04) 86.1%, rgba(0,0,0, 0.02) 91%, rgba(0,0,0, 0.01) 95.2%, rgba(0,0,0, 0) 98.2%, rgba(0,0,0, 0));
  }
}

.logo {
  > img {
    display: block;
    margin: 0 auto;
  }
}

.content {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
}

.grid {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 33.33% 33.34% 33.33%;
  padding-bottom: 4rem;
  position: relative;
  z-index: 1;
}

.oracle {
  width: 100%;
  padding: 1rem;
}

.oracle--1,
.oracle--3,
.oracle--5 {
  padding-top: 8rem;
}

.oracle__image {
  width: 20vw;
  max-width: 10rem;
  margin: 0 auto;
  margin-top: -5%;
  margin-bottom: -12.5%;

  > img {
    display: block;
    max-width: 100%;
  }
}

.oracle__callouts {
  padding: 1rem;
  margin: 0 auto;
  max-width: 20rem;
  background: rgba(0, 0, 0, 0.4);
  border-radius: .5rem;

  > label {
    font-size: .6em;
    opacity: .6;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: .5em;
    display: block;
  }
}

@media all and (max-width: 768px) {
  .grid {
    font-size: .8em;
  }
}

@media all and (max-width: 640px) {
  .grid {
    grid-template-columns: 16.66% 16.66% 16.66% 16.66% 16.66% 16.66%;
  }

  .oracle {
    padding: 0;
  }

  .oracle--2 {
    grid-row: 1;
    grid-column: 2 / span 4;
  }

  .oracle--1 {
    grid-row: 2;
    grid-column: span 4;
    padding-left: 1rem;
    padding-right: .5rem;
    margin-top: -2.5rem;
    z-index: 1;

    .oracle__image {
      margin-left: 0;
    }
  }

  .oracle--3 {
    grid-row: 3;
    grid-column: 3 / span 4;
    padding-right: 1rem;
    padding-left: .5rem;
    margin-top: -2.5rem;

    .oracle__image {
      margin-right: 0;
    }
  }

  .oracle--4 {
    grid-row: 4;
    grid-column: span 4;
    padding-left: 1rem;
    padding-right: .5rem;
    margin-top: -2.5rem;

    .oracle__image {
      margin-left: 0;
    }
  }

  .oracle--6 {
    grid-row: 5;
    grid-column: 3 / span 4;
    padding-right: 1rem;
    padding-left: .5rem;
    margin-top: -2.5rem;

    .oracle__image {
      margin-right: 0;
    }
  }

  .oracle--5 {
    grid-row: 6;
    grid-column: 2 / span 4;
  }

  .oracle__callouts label span {
    display: none;
  }
}

.callout {
  display: flex;
  overflow: hidden;
}

.callout--rank1 {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: .5rem;
}

.callout--rank2 {
  opacity: .8;
  color: #CFD8DC;
  margin-bottom: .25rem;
}

.callout--rank3 {
  color: #B0BEC5;
  opacity: .6;
}

.callout__rank {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  height: 1.5em;
  width: 1.5em;
  min-width: 1.5em;
  margin-right: .33rem;

  &:after {
    font-size: .8em;
    font-weight: 700;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    content: attr(data-rank);
  }
}

.callout__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.callout__change {
  display: flex;
  align-items: center;
  margin-left: .25rem;
  font-weight: 700;
}

.callout__change--positive {
  color: #00E676;
}

.callout__change--negative {
  color: #FF1744;
}

.callout__rank[data-rank="1"] {
  background-image: url('/img/laurel-gold.svg');
}

.callout__rank[data-rank="2"] {
  background-image: url('/img/laurel-silver.svg');
}


.callout__rank[data-rank="3"] {
  background-image: url('/img/laurel-bronze.svg');
}

footer {
  flex: 0 0 auto;
  padding: 1rem;
  text-align: center;
  position: relative;
  font-size: small;
  opacity: .6;

  > span {
    position: relative;
    z-index: 1;
  }

  a {
    color: inherit;
    opacity: .8;
    text-decoration: none;
  }

  &:before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: -4rem;
    pointer-events: none;
    content: "";
    opacity: .8;
    background-image: linear-gradient(0deg,#000 0,rgba(0,0,0,.74) 19%,rgba(0,0,0,.54) 34%,rgba(0,0,0,.38) 47%,rgba(0,0,0,.28) 56.5%,rgba(0,0,0,.19) 65%,rgba(0,0,0,.13) 73%,rgba(0,0,0,.08) 80.2%,rgba(0,0,0,.04) 86.1%,rgba(0,0,0,.02) 91%,rgba(0,0,0,.01) 95.2%,rgba(0,0,0,0) 98.2%,rgba(0,0,0,0))
  }
}
</style>
